import axios from '@axios'

export const unityVersionEditorStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        selectedUnityVersionEditors: {},
        createData: [],
        isLoading: true,
    },
    mutations: {
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
    },
    actions: {
        fetchUnityVersionEditors(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/unity-editor-versions', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchUnityVersionEditor(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/unity-editor-versions/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addUnityVersionEditor(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/unity-editor-versions', queryParams)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateUnityVersionEditor(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .put(`/unity-editor-versions/${queryParams.id}`, queryParams)
                    .then(response => {
                        resolve(response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
        },
        deleteUnityVersionEditor(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/unity-editor-versions/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
