import axios from '@axios'

export const userRoleStore = {
  namespaced: true,
  state: {
    data: [],
    pagination: {},
    params: {},
    selectedUser: {},
    isLoading: true
  },
  getters: {},
  mutations: {},
  actions: {
 
    fetchUserRoles(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('user/role', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/role',  queryParams )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateRole(ctx, userData) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/user/role/${userData.id}`, userData )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteRole(ctx,  {id}) {
        return new Promise((resolve, reject) => {
          axios
            .delete(`/user/role/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },

  },
}
