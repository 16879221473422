import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './modules/appModule'
import appConfig from './modules/appConfig'
import verticalMenu from './modules/verticalMenu'
import {auth} from './modules/authStore'
import {userStore} from './modules/userStore'
import {userRoleStore} from './modules/userRoleStore'
import {discordStore} from './modules/discordStore'
import {userRolePermissionStore} from './modules/userRolePermissionStore'
import {buildStore} from './modules/buildStore'
import {buildErrorAnalysisStore} from './modules/buildErrorAnalysisStore'
import {conceptStore} from './modules/conceptStore'
import {campaignStore} from './modules/campaignStore'
import {discordChannelStore} from './modules/discordChannelStore'
import {settingStore} from './modules/settingStore'
import {bitbucketStore} from './modules/bitbucketStore'
import {appStore} from './modules/appStore'
import {networkAccountStore} from './modules/networkAccountStore'
import {networkStore} from './modules/networkStore'
import {storeAccountStore} from './modules/storeAccountStore'
import {asanaStore} from './modules/asanaStore'
import {teamStore} from './modules/teamStore'
import {activityLogStore} from './modules/activityLogStore'
import {buildServerStore} from './modules/buildServerStore'
import {unityVersionEditorStore} from './modules/unityVersionEditorStore'
import {unityStore} from './modules/unityStore'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        auth,
        userStore,
        userRoleStore,
        userRolePermissionStore,
        discordStore,
        discordChannelStore,
        buildStore,
        buildErrorAnalysisStore,
        conceptStore,
        settingStore,
        campaignStore,
        bitbucketStore,
        appStore,
        networkStore,
        networkAccountStore,
        storeAccountStore,
        asanaStore,
        teamStore,
        activityLogStore,
        buildServerStore,
        unityVersionEditorStore,
        unityStore
    },
    strict: process.env.DEV,
})

