import axios from '@axios'

export const campaignStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        createData: [],
        statusList: {
            WAITING: "Waiting",
            LIVE: "Live",
            CLOSED: "Closed",
        },
        isLoading: true,
        uploadProgress: 0,
    },
    getters: {},
    mutations: {
        SET_UPLOAD_PROGRESS(state, uploadProgress) {
            state.uploadProgress = uploadProgress
        }
    },
    actions: {
        fetchCampaigns(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/campaign', {
                        params: queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCampaign(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/campaign/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addCampaign(ctx, queryParams) {
            let config = {
                headers: {
                    'Content-Type': "multipart/form-data;application/json; boundary=" + Math.random().toString().substr(2)
                },
                onUploadProgress: function (progressEvent) {
                    ctx.commit('SET_UPLOAD_PROGRESS', Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            }
            return new Promise((resolve, reject) => {
                axios
                    .post('/campaign', queryParams, config)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewAppstore(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/campaign/app/preview`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateCampaign(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/campaign/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteCampaign(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/campaign/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

    },
}
