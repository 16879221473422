import axios from '@axios'

export const activityLogStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        selectedActivityLog: {},
        isLoading: true,
    },
    actions: {
        fetchActivityLogs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/activity-logs', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActivityLog(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activity-logs/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchActivityLogTypes(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/activity-logs/types/list`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
