import axios from '@axios'

export const discordChannelStore = {
    namespaced: true,
    state: {
        data: [],
        params: {},
        pagination: {},
    },

    mutations: {
        
    },
    actions: {
        fetchChannels(ctx, queryParams) {
            return new Promise((resolve, reject) => {
            axios
                .get('/discord/channel', { params: queryParams })
                .then((response)=>{resolve(response) })
                .catch(error => reject(error))
            })
        },

        updateChannel(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                .put(`/discord/channel/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    },
}