import axios from '@axios'

export const buildServerStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        selectedBuildServers: {},
        createData: [],
        isLoading: true,
    },
    mutations: {
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
    },
    actions: {
        fetchBuildServers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/build-servers', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchBuildServer(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/build-servers/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        generateToken(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/build-servers/generate-token`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addBuildServer(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/build-servers', queryParams)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateBuildServer(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .put(`/build-servers/${queryParams.id}`, queryParams)
                    .then(response => {
                        resolve(response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
        },
        deleteBuildServer(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/build-servers/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
