import axios from '@axios'

export const networkStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        selectedNetworks: {},
        createData: [],
        isLoading: true,
    },
    actions: {
        fetchNetworks(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get('/networks', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchNetwork(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/networks/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
