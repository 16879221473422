import axios from '@axios'

export const userStore = {
  namespaced: true,
  state: {
    data: [],
    pagination: {},
    params: {},
    selectedUser: {},
    isLoading: true,
    teams:{}
  },
  getters: {},
  mutations: {
    FETCH_TEAMS(state, data) {
      state.teams = data.data.data
      state.pagination = data.data.pagination
    },
    UPDATE_IS_LOADING(state, status) {
        state.isLoading = status
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUserRoles(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('user/role', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user',  queryParams )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateUser(ctx, userData) {
        return new Promise((resolve, reject) => {
          axios
            .put(`/user/${userData.id}`, userData )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteUser(ctx, {id}) {
        return new Promise((resolve, reject) => {
          axios
            .delete(`/user/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchUnpairedUsers(ctx,queryParams){
      return new Promise((resolve, reject) => {
        axios
          .get('/unpaired/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTeams(ctx, queryParams) {
      ctx.commit('UPDATE_IS_LOADING', true)
      return new Promise((resolve, reject) => {
          axios
              .get(`/teams`)
              .then(response => {
                  resolve(response)
                  ctx.commit("FETCH_TEAMS", response)
                  ctx.commit('UPDATE_IS_LOADING', false)
              })
              .catch(error => reject(error))
      })
  	},
  },
}
