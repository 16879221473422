import axios from '@axios'

export const appStore = {
	namespaced: true,
	state: {
		data: [],
		pagination: {},
		params: {},
		selectedApp: {},
		createData: [],
		isLoading: false,
		appAccounts: []
	},
	mutations: {
		FETCH_ACCOUNTS(state, data) {
			state.appAccounts = data.data.data
		},
		UPDATE_IS_LOADING(state, status) {
			state.isLoading = status
		},
		RESET_DETAIL(state) {
			state.appAccounts = []
		},
	},
	actions: {
		fetchApps(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/app', {
						params: queryParams
					})
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchApp(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/app/${id}`)
					.then(response =>{
						 resolve(response)
						 ctx.commit('UPDATE_IS_LOADING', false)
					})
					.catch(error => reject(error))
			})
		},
		fetchEditApp(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/queue/edit/app/${id}`)
					.then(response =>{
						 resolve(response)
						 ctx.commit('UPDATE_IS_LOADING', false)
					})
					.catch(error => reject(error))
			})
		},
		addApp(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.post('/app', queryParams,{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addAppManual(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.post('/app/manual', queryParams,{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		updateAppQueue(ctx, queryParams) {
			ctx.commit('UPDATE_IS_LOADING', true)
			return new Promise((resolve, reject) => {
				axios
					.post(`/queue/update/app`, queryParams)
					.then(response => {
						resolve(response)
						ctx.commit('UPDATE_IS_LOADING', false)
					})
					.catch(error => {
						reject(error)
						ctx.commit('UPDATE_IS_LOADING', false)
					})
			})
		},
		updateApp(ctx, queryParams) {
			ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .post(`/app/${queryParams.get('id')}`, queryParams,{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
                    .then(response => {
                        resolve(response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
		},
		updateManualApp(ctx, queryParams) {
			ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .post(`/app/manual/${queryParams.get('id')}`, queryParams,{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
                    .then(response => {
                        resolve(response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
		},
		deleteApp(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/app/${id}`)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchAccounts(ctx, queryParams) {
			ctx.commit('UPDATE_IS_LOADING', true)
			return new Promise((resolve, reject) => {
				axios
					.get(`/accounts/app`)
					.then(response => {
						resolve(response)
						ctx.commit("FETCH_ACCOUNTS", response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		fetchUserRoles(ctx, queryParams) {
			ctx.commit('UPDATE_IS_LOADING', true)
			return new Promise((resolve, reject) => {
				axios
					.get(`/app/role/users`,{params:queryParams})
					.then(response => {
						resolve(response)
						ctx.commit('UPDATE_IS_LOADING', false)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		resetDetailView(ctx) {
			ctx.commit('RESET_DETAIL')
		},
		isLoadingSet(ctx) {
			ctx.commit('RESET_DETAIL')
		},
	},
}
