import jwtDefaultConfig from './jwtDefaultConfig'
import _ from 'lodash';
import router from '@/router';
import store from '@/store';
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }
  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {

    this.axiosIns = axiosIns
    this.axiosIns.defaults.withCredentials = true
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        if(response && (response.status == 401)){
          localStorage.removeItem('toPath')
          store.dispatch('auth/handleLogout')
          router.push({ name: 'auth-login' })
        }
        if(response && (response.status === 503)){
          router.push({ name: 'maintenance-mode'})
        }
        return Promise.reject(error)
      },
    )
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }
}
