import axios from "@axios";
import Vue from 'vue';


export const buildStore = {
  namespaced: true,
  state: {
    data:[],
    pagination:{},
    params:{},
    selectedBuild:{},
    downloadFile:'',
    statusList: {
        WAITING: "Waiting",
        IN_PROGRESS: "In Progress",
        FAILED: "Failed",
        SUCCEEDED: "Succeeded"
    },
    osList: {
        ANDROID: "Android",
        IOS: "Ios"
    },
  },
  mutations: {
    FETCH_BUILDS(state, data) {
      state.data = data.data.data;
      state.pagination = data.data.pagination
    },
    DELETE_BUILD(state, id){
      index = state.data.findIndex(data => data.id == id);
      state.data.splice(index, 1);
    }

  },
  actions: {

    fetchBuilds(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/build-jobs', { params: queryParams })
          .then((response)=>{
            resolve(response)
            ctx.commit('FETCH_BUILDS', response);
          })
          .catch(error => reject(error))
      })
    },

    fetchBuild(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/build-jobs/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadBuild(ctx,{id}){
      return new Promise((resolve, reject) => {
        axios
          .post(`/build-jobs/${id}/download`)
          .then(response =>{
             resolve(response)
            })
          .catch(error => {
            const { status, data } = error.response;
            console.log(data.message)
            reject(error)
          })
      })
    },
    restartBuild(ctx,{id}){
      return new Promise((resolve, reject) => {
        axios
          .post(`/build-jobs/${id}/restart`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBuild(ctx,{id}){
      return new Promise((resolve, reject) => {
        axios
          .delete(`/build-jobs/${id}`)
          .then(response => {
            resolve(response)
            ctx.commit('DELETE_BUILD', id);
          })
          .catch(error => reject(error))
      })
    },
    createQA(ctx,queryParams){
      return new Promise((resolve, reject) => {
        axios
          .post(`/asana/task/create/${queryParams.id}`, { params: queryParams }, )
          .then((response)=>{resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchDevelopers(ctx,queryParams){
      return new Promise((resolve, reject) => {
        axios
            .get('/build/developer/list', {params: queryParams})
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
      })
    }

  },

};
