export default [
    {
        path: '/',
        name: 'home',
        redirect: 'build/list',
        component: () => import('@/views/Home.vue'),
        meta: {
            requiresAuth: true,
            pageTitle: 'Home',
            action: 'read',
            resource: 'home',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/build/list',
        name: 'build-list',
        component: () => import('@/views/buildManager/BuildList.vue'),
        meta: {
            requiresAuth: true,
            pageTitle: 'Build Manager',
            action: 'read',
            resource: 'buildList',
            breadcrumb: [
                {
                    text: 'Build Manager',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/builds/detail/:build',
        name: 'build-detail',
        component: () => import('@/views/buildManager/BuildDetail.vue'),
        meta: {
            requiresAuth: true,
            pageTitle: 'Build Detail',
            action: 'read',
            resource: 'buildList',
            navActiveLink: 'build-list',
            breadcrumb: [
                {
                    text: 'Build Detail',
                    active: true,
                },
            ],
        },
    },


    {
        path: '/buildErrorAnalysis/list',
        name: 'buildErrorAnalysis-list',
        component: () => import('@/views/buildErrorAnalysis/BuildErrorAnalysisList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'buildErrorAnalysisList',
            pageTitle: 'Build Error Analysis List',
            breadcrumb: [
                {
                    text: 'Build Error Analysis List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/buildErrorAnalysis/create',
        name: 'buildErrorAnalysis-create',
        component: () => import('@/views/buildErrorAnalysis/BuildErrorAnalysisCreate.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'buildErrorAnalysisCreate',
            pageTitle: 'Build Error Analysis Create',
            navActiveLink: 'buildErrorAnalysis-list',
            breadcrumb: [
                {
                    text: 'Build Error Analysis Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/buildErrorAnalysis/edit/:buildErrorAnalysis',
        name: 'buildErrorAnalysis-edit',
        component: () => import('@/views/buildErrorAnalysis/BuildErrorAnalysisEdit.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'buildErrorAnalysisEdit',
            pageTitle: 'Build Error Analysis Edit',
            navActiveLink: 'buildErrorAnalysis-list',
            breadcrumb: [
                {
                    text: 'Build Error Analysis Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/build/server/list',
        name: 'buildServer-list',
        component: () => import('@/views/buildServer/BuildServerList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'buildServerList',
            pageTitle: 'Build Servers',
            breadcrumb: [
                {
                    text: 'Build Servers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/unity/edtior/version/list',
        name: 'unityEditorVersionList-list',
        component: () => import('@/views/unityEditorVersion/UnityEditorVersionList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'unityEditorVersionList',
            pageTitle: 'Unity Editor Versions',
            breadcrumb: [
                {
                    text: 'Unity Editor Versions',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/discord/user/list',
        name: 'discord-list',
        component: () => import('@/views/discordTracker/DiscordTeamView.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'discordUserList',
            pageTitle: 'Discord Team View',
            breadcrumb: [
                {
                    text: 'Discord Team View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/discord/user/detail/:userid?',
        name: 'discord-detail',
        component: () => import('@/views/discordTracker/DiscordDetailView.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'discordUserList',
            pageTitle: 'Discord Detail View',
            breadcrumb: [
                {
                    text: 'Discord Detail View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/discord/channel/list',
        name: 'discord-channel-list',
        component: () => import('@/views/discordTracker/DiscordChannelList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'discordUserList',
            pageTitle: 'Discord Channel List',
            breadcrumb: [
                {
                    text: 'Discord Channel List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/bitbucket/repositories',
        name: 'bitbucket-repositories',
        component: () => import('@/views/bitbucket/BitbucketRepositoryList.vue'),
        meta: {
            action: 'read',
            requiresAuth: true,
            resource: 'bitbucketTracker',
            pageTitle: 'Bitbucket Repositories',
            breadcrumb: [
                {
                    text: 'Bitbucket Repositories',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/bitbucket/user',
        name: 'bitbucket-user-list',
        component: () => import('@/views/bitbucket/BitbucketUserList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'bitbucketTracker',
            pageTitle: 'Bitbucket User List',
            breadcrumb: [
                {
                    text: 'Bitbucket User List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/unity/user/list',
        name: 'unity-list',
        component: () => import('@/views/unity/UnityUserList.vue'),
        meta: {
            action: 'read',
            requiresAuth: true,
            resource: 'unityTracker',
            pageTitle: 'Unity Team List',
            breadcrumb: [
                {
                    text: 'Unity Tracker',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/unity/user/detail/:userid?',
        name: 'unity-detail',
        component: () => import('@/views/unity/UnityUserDetail.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'unityTracker',
            pageTitle: 'Unity Team Detail',
            breadcrumb: [
                {
                    text: 'Unity Team Detail',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/concept/list',
        name: 'concept-list',
        component: () => import('@/views/concept/ConceptList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'conceptList',
            pageTitle: 'Concept List',
            breadcrumb: [
                {
                    text: 'Concept List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/concept/detail/:concept',
        name: 'concept-detail',
        component: () => import('@/views/concept/ConceptDetail.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'conceptList',
            pageTitle: 'Concept Detail',
            navActiveLink: 'concept-list',
            breadcrumb: [
                {
                    text: 'Concept Detail',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/concept/create',
        name: 'concept-create',
        component: () => import('@/views/concept/ConceptCreate.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'conceptCreate',
            pageTitle: 'Concept Create',
            breadcrumb: [
                {
                    text: 'Concept Create',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/concept/edit/:concept',
        name: 'concept-edit',
        component: () => import('@/views/concept/ConceptEdit.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'conceptEdit',
            pageTitle: 'Concept Edit',
            navActiveLink: 'concept-list',
            breadcrumb: [
                {
                    text: 'Concept Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/app/list',
        name: 'app-list',
        component: () => import('@/views/appManager/AppList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'appList',
            pageTitle: 'App List',
            breadcrumb: [
                {
                    text: 'App List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/app/detail/:app',
        name: 'app-detail',
        component: () => import('@/views/appManager/AppDetail.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'appList',
            pageTitle: 'App Detail',
            navActiveLink: 'app-list',
            breadcrumb: [
                {
                    text: 'App Detail',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/app/create',
        name: 'app-create',
        component: () => import('@/views/appManager/AppCreate.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'appList',
            navActiveLink: 'app-list',
            pageTitle: 'App Create',
            breadcrumb: [
                {
                    text: 'App Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/app/create/manual',
        name: 'app-create-manual',
        component: () => import('@/views/appManager/AppCreateManual.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'appList',
            navActiveLink: 'app-list',
            pageTitle: 'App Create',
            breadcrumb: [
                {
                    text: 'App Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/app/edit/:app/manual',
        name: 'app-edit-manual',
        component: () => import('@/views/appManager/AppEditManual.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'appEdit',
            pageTitle: 'App Manual Edit',
            navActiveLink: 'app-list',
            breadcrumb: [
                {
                    text: 'App Manual Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/app/edit/:app',
        name: 'app-edit',
        component: () => import('@/views/appManager/AppEdit.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'appEdit',
            pageTitle: 'App Edit',
            navActiveLink: 'app-list',
            breadcrumb: [
                {
                    text: 'App Edit',
                    active: true,
                },
            ],
        },
    },



    {
        path: '/store/account/list',
        name: 'store-account-list',
        component: () => import('@/views/storeAccount/StoreAccountList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'storeAccountList',
            pageTitle: 'Store Account List',
            breadcrumb: [
                {
                    text: 'Store Account List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/store/account/create',
        name: 'store-account-create',
        component: () => import('@/views/storeAccount/StoreAccountListCreate.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'storeAccountCreate',
            pageTitle: 'Store Account Create',
            breadcrumb: [
                {
                    text: 'Store Account Create',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/store/account/edit/:app',
        name: 'store-account-edit',
        component: () => import('@/views/storeAccount/StoreAccountListEdit.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'storeAccountEdit',
            pageTitle: 'Store Account Edit',
            navActiveLink: 'store-account-list',
            breadcrumb: [
                {
                    text: 'Store Account Edit',
                    active: true,
                },
            ],
        },
    },



    {
        path: '/network/account/list',
        name: 'network-account-list',
        component: () => import('@/views/networkAccount/NetworkAccountList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'networkAccountList',
            pageTitle: 'Network Account List',
            breadcrumb: [
                {
                    text: 'Network Account List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/network/account/create',
        name: 'network-account-create',
        component: () => import('@/views/networkAccount/NetworkAccountCreate.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            navActiveLink: 'network-account-list',
            resource: 'networkAccountCreate',
            pageTitle: 'Network Account Create',
            breadcrumb: [
                {
                    text: 'Network Account Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/network/account/edit/:networkAccount',
        name: 'network-account-edit',
        component: () => import('@/views/networkAccount/NetworkAccountEdit.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'networkAccountEdit',
            navActiveLink: 'network-account-list',
            pageTitle: 'Network Account Edit',
            breadcrumb: [
                {
                    text: 'Network Account Edit',
                    active: true,
                },
            ],
        },
    },


    {
        path: '/campaign/list',
        name: 'campaign-list',
        component: () => import('@/views/campaignManagement/CampaignList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'campaignList',
            pageTitle: 'Campaign List',
            breadcrumb: [
                {
                    text: 'Campaign List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/campaign/detail/:campaign',
        name: 'campaign-detail',
        component: () => import('@/views/campaignManagement/CampaignDetail.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'campaignList',
            pageTitle: 'Campaign Detail',
            navActiveLink: 'campaign-list',
            breadcrumb: [
                {
                    text: 'Campaign Detail',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/campaign/create',
        name: 'campaign-create',
        component: () => import('@/views/campaignManagement/CampaignCreate.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'campaignCreate',
            pageTitle: 'Campaign Create',
            breadcrumb: [
                {
                    text: 'Campaign Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/list',
        name: 'user-list',
        component: () => import('@/views/userAdmin/users/UserList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'userList',
            pageTitle: 'User List',
            breadcrumb: [
                {
                    text: 'User List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/role/list',
        name: 'user-role-list',
        component: () => import('@/views/userAdmin/userRole/UserRoleList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'userRoleList',
            pageTitle: 'User Role List',
            breadcrumb: [
                {
                    text: 'User Role List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/unpaired/users',
        name: 'unpaired-users',
        component: () => import('@/views/userAdmin/unpairedUsers/UnpairedUsers.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'userList',
            pageTitle: 'Unpaired Users',
            breadcrumb: [
                {
                    text: 'Unpaired Users',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/team/list',
        name: 'team-list',
        component: () => import('@/views/teams/TeamList.vue'),
        meta: {
            requiresAuth: true,
            action: 'read',
            resource: 'teamList',
            pageTitle: 'Team List',
            breadcrumb: [
                {
                    text: 'Team List',
                    active: true,
                },
            ],
        },
    },


    {
        path: '/settings',
        name: 'setting-list',
        component: () => import('@/views/setting/SettingList.vue'),
        meta: {
            requiresAuth: true,
            pageTitle: 'Settings',
            action: 'read',
            resource: 'settingList',
            breadcrumb: [
                {
                    text: 'Settings',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/activity-logs',
        name: 'activity-log-list',
        component: () => import('@/views/activityLog/ActivityLogList.vue'),
        meta: {
            requiresAuth: true,
            pageTitle: 'Activity Logs',
            action: 'read',
            resource: 'activityLogList',
            breadcrumb: [
                {
                    text: 'Activity Logs',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/Profile.vue'),
        meta: {
            requiresAuth: true,
            pageTitle: 'Profile',
            breadcrumb: [
                {
                    text: 'Profile',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/auth/login',
        name: 'auth-login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            authRoute: true,
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '*',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            publicRoute: true,
            layout: 'full',
        },

    },
    {
        path: '/misc/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/error/NotAuth.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            requiresAuth: true,
        },
    },
    {
        path: '/unityauth',
        name: 'unityauth',
        component: () => import('@/views/unityAuth/UnityAuth.vue'),
        meta: {
            layout: 'full',
            requiresAuth: true,
            resource: 'buildList'
        }
    },
    {
        path: '/maintenance/',
        name: 'maintenance-mode',
        component: () => import('@/views/error/Maintenance.vue'),
        meta: {
            layout: 'full',
            requiresAuth: true,
            resource: 'buildList'
        },
    },
]
