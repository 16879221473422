import axios from "@axios";

export const storeAccountStore = {
    namespaced: true,
    state: {
        data: [],
        isLoading: false
    },
    mutations: {
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
    },
    actions: {
        fetchAppstoreAccounts(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get('/store-account/appstore-accounts', {params: queryParams})
                    .then((response) => {
                        resolve(response)
                    }).catch(error => reject(error))
            })
        },
        fetchAppstoreAccount(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/store-account/appstore-accounts/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAppstoreAccount(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.post('/store-account/appstore-accounts', queryParams)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateAppstoreAccount(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.put(`/store-account/appstore-accounts/${queryParams.id}`, queryParams).then(response => {
                    resolve(response)
                    ctx.commit('UPDATE_IS_LOADING', false)
                }).catch(error => {
                    reject(error)
                    ctx.commit('UPDATE_IS_LOADING', false)
                })
            })
        },
        deleteAppstoreAccount(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios.delete(`/store-account/appstore-accounts/${id}`).then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },


        /** Android Accounts */
        fetchAndroidAccounts(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get('/store-account/android-accounts', {params: queryParams})
                    .then((response) => {
                        resolve(response)
                    }).catch(error => reject(error))
            })
        },
        fetchAndroidAccount(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/store-account/android-accounts/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAndroidAccount(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.post('/store-account/android-accounts', queryParams)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateAndroidAccount(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.put(`/store-account/android-accounts/${queryParams.id}`, queryParams).then(response => {
                    resolve(response)
                    ctx.commit('UPDATE_IS_LOADING', false)
                }).catch(error => {
                    reject(error)
                    ctx.commit('UPDATE_IS_LOADING', false)
                })
            })
        },
        deleteAndroidAccount(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios.delete(`/store-account/android-accounts/${id}`).then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
};
