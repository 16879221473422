import axios from '@axios'

export const asanaStore = {
    namespaced: true,
    state: {
        data: [],
        params: {},
        pagination: {},
        commitDetail: [],
        isLoading: false,
    },

    mutations: {
    },
    actions: {
        fetchAsanaUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get('/asana/members')
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    },
}
