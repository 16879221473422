
const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const auth = {
  namespaced: true,
  state: {
    userData: initialUser()
  },
  actions: {
    handleLogin({ commit }, user) {
      commit.userData = user
      commit['accessToken'] = user.accessToken
      localStorage.setItem('userData', JSON.stringify(user))
      localStorage.setItem('accessToken', user.accessToken)
    },
    handleLogout({ commit }) {
      commit.userData = {}
      commit['accessToken'] = null
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('toPath')
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.userData.loggedIn = true;
      state.userData = user;
    },
    loginFailure(state) {
      state.userData.loggedIn = false;
      state.userData = null;
    },
    logout(state) {
      state.userData.loggedIn = false;
      state.userData = null;
    },

  }
};
