import axios from '@axios'

export const teamStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        selectedTeams: {},
        createData: [],
        isLoading: true,
    },
    mutations: {
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
    },
    actions: {
        fetchTeams(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/teams', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTeam(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/teams/${queryParams.id}`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addTeam(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/teams', queryParams)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateTeam(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .put(`/teams/${queryParams.id}`, queryParams)
                    .then(response => {
                        resolve(response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
        },
        deleteTeam(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/teams/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
