import axios from '@axios'

export const networkAccountStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        selectedNetworkAccounts: {},
        createData: [],
        isLoading: true,
    },
    mutations: {
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
    },
    actions: {
        fetchNetworkAccounts(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get('/network-accounts', {params: queryParams}).then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchNetworkAccount(ctx, queryParams) {
            console.log(queryParams)
            return new Promise((resolve, reject) => {
                axios.get(`/network-accounts/${queryParams.id}`, queryParams).then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addNetworkAccount(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.post('/network-accounts', queryParams).then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateNetworkAccount(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.put(`/network-accounts/${queryParams.id}`, queryParams).then(response => {
                    resolve(response)
                    ctx.commit('UPDATE_IS_LOADING', false)
                }).catch(error => {
                    reject(error)
                    ctx.commit('UPDATE_IS_LOADING', false)
                })
            })
        },
        deleteNetworkAccount(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios.delete(`/network-accounts/${id}`).then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
