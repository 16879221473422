import axios from '@axios'

export const discordStore = {
    namespaced: true,
    state: {
        data: [],
        params: {},
        pagination: {},
    },

    mutations: {
        FETCH_DISCORD(state, data) {
            state.data = data.data.data;
            state.pagination = data.data.pagination
        }
    },
    actions: {
        fetchDiscord(ctx, queryParams) {
            return new Promise((resolve, reject) => {
            axios
                .get('/discord/user', { params: queryParams })
                .then((response)=>{
                    resolve(response)
                    ctx.commit("FETCH_DISCORD", response);
                })
                .catch(error => reject(error))
            })
        },

        fetchDiscordUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get('/discord/user/list', { params: queryParams })
                .then(response => {resolve(response)
                isLoading=false})
                .catch(error => reject(error))
            })
        },

        fetchDiscordAllUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get('/discord/user/all')
                .then(response => {resolve(response)
                isLoading=false})
                .catch(error => reject(error))
            })
        },

        fetchDetailView(ctx, queryParams) {
            return new Promise((resolve, reject) => {
            axios
                .get(`/discord/user/${queryParams.id}`, { params: queryParams })
                .then(response => {resolve(response)
                isLoading=false})
                .catch(error => reject(error))
            })
        },

        fetchChannelInfo(ctx, id) {
            return new Promise((resolve, reject) => {
            axios
                .get(`/discord/duration/info/${id}`)
                .then(response => {resolve(response)
                isLoading=false})
                .catch(error => reject(error))
            })
        },
    },
}
