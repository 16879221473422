import axios from '@axios'

export const unityStore = {
    namespaced: true,
    state: {
        data: [],
        params: {},
        pagination: {},
        unityDetail: [],
        isLoading: false,
    },

    mutations: {
        FETCH_UNITY_USERS(state, data) {
            state.data = data.data.data
            state.pagination = data.data.pagination
        },
        FETCH_UNITY_DETAIL(state, data) {
            state.unityDetail = data.data.data
        },
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
        RESET_DETAIL(state) {
            state.unityDetail = []
        }
    },
    actions: {
        fetchUnityUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get('/unity/user', {
                    params: queryParams
                }).then((response) => {
                    resolve(response)
                    ctx.commit("FETCH_UNITY_USERS", response);
                }).catch(error => reject(error))
            })
        },
        fetchUserDetailView(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`/unity/user/${queryParams.id}`, {
                    params: queryParams
                }).then(response => {
                    resolve(response)
                    ctx.commit("FETCH_UNITY_DETAIL", response)
                    ctx.commit('UPDATE_IS_LOADING', false)
                }).catch(error => reject(error))
            })
        },
        fetchUserInfoView(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`/unity/user/info/${queryParams.id}`, {
                    params: queryParams
                }).then(response => {
                    resolve(response)
                    ctx.commit("FETCH_UNITY_DETAIL", response)
                    ctx.commit('UPDATE_IS_LOADING', false)
                }).catch(error => reject(error))
            })
        },
        resetDetailView(ctx) {
            ctx.commit('RESET_DETAIL')
        },
        fetchUnityAllUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get('/unity/user/list', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
