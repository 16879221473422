import axios from "@axios";

export const settingStore = {
  namespaced: true,
  state: {
    data:[],
  },
  mutations: {
    FETCH_SETTINGS(state, data) {
      state.data = data.data.data;
    },

  },
  actions: {
    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/settings', { params: queryParams })
          .then((response)=>{
            resolve(response)
            ctx.commit('FETCH_SETTINGS', response);
          })
          .catch(error => reject(error))
      })
    },
    fetchMaintenance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/maintenance/status')
          .then((response)=>{
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMaintenanceMode(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/maintenance/mode`,queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSetting(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/settings/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAltSetting(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/alt`,queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSetting(ctx, queryParams) {
      return new Promise((resolve, reject) => {
      axios
        .post('/settings',  queryParams )
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    updateSetting(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/settings/${queryParams.id}`, queryParams )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSetting(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/settings/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAltSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
      axios
        .post('/settings/update/alt',  queryParams)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },

  },

};