import axios from '@axios'

export const conceptStore = {
    namespaced: true,
    state: {
        data: [],
        pagination: {},
        params: {},
        selectedConcept: {},
        createData: [],
        statusList: {
            WAITING_APPROVAL: "Waiting Approval",
            APPROVED: "Approved",
            DEVELOPMENT: "Development",
            KILLED: "Killed",
            LEAD_CAMPAIGN: "Lead Campaign"
        },
        isLoading: true,
        conceptShortCut: [],
        conceptPms: []
    },
    mutations: {
        FETCH_CONCEPT_MODAL_DETAIL(state, data) {
            state.conceptShortCut = data.data.data
        },
        FETCH_CONCEPT_PMS(state, data) {
            state.conceptPms = data.data.data
        },
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
        RESET_DETAIL(state) {
            state.conceptShortCut = []
        },
    },
    actions: {
        fetchConcepts(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/concept', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchConcept(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/concept/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchGenre(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/concept/genre', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCamera(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/concept/camera', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPms(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/concept/pm', { params: queryParams })
                    .then(response => {
                        resolve(response)
                        ctx.commit("FETCH_CONCEPT_PMS", response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
        },
        addConcept(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/concept', queryParams)
                    .then(response => resolve(response))
                    .catch(error => console.log(error))
            })
        },
        updateConcept(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .put(`/concept/${queryParams.id}`, queryParams)
                    .then(response => {
                        resolve(response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
        },
        deleteConcept(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/concept/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchConceptShortCut(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .get(`/concept/${queryParams.id}`, {
                        params: queryParams
                    })
                    .then(response => {
                        resolve(response)
                        ctx.commit("FETCH_CONCEPT_MODAL_DETAIL", response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => {
                        reject(error)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
            })
        },
        resetDetailView(ctx) {
            ctx.commit('RESET_DETAIL')
        },
        isLoadingSet(ctx) {
            ctx.commit('RESET_DETAIL')
        },

    },
}
