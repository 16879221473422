import axios from '@axios'

export const bitbucketStore = {
    namespaced: true,
    state: {
        data: [],
        params: {},
        pagination: {},
        commitDetail: [],
        isLoading: false,
    },

    mutations: {
        FETCH_BITBUCKET_USERS(state, data) {
            state.data = data.data.data
            state.pagination = data.data.pagination
        },
        FETCH_BITBUCKET_DETAIL(state, data) {
            state.commitDetail = data.data.data
        },
        FETCH_BITBUCKET_REPOSITORIES(state, data) {
            state.data = data.data.data
            state.pagination = data.data.pagination
        },
        UPDATE_IS_LOADING(state, status) {
            state.isLoading = status
        },
        RESET_DETAIL(state) {
            state.commitDetail = []
        }
    },
    actions: {
        fetchBitbucketUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/bitbucket/user', {
                        params: queryParams
                    })
                    .then((response) => {
                        resolve(response)
                        ctx.commit("FETCH_BITBUCKET_USERS", response);
                    })
                    .catch(error => reject(error))
            })
        },
        fetchBitbucketRepo(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/bitbucket/repositories', {
                        params: queryParams
                    })
                    .then((response) => {
                        resolve(response)
                        ctx.commit("FETCH_BITBUCKET_REPOSITORIES", response);
                    })
                    .catch(error => reject(error))
            })
        },
        fetchUserDetailView(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .get(`/bitbucket/user/${queryParams.id}`, {
                        params: queryParams
                    })
                    .then(response => {
                        resolve(response)
                        ctx.commit("FETCH_BITBUCKET_DETAIL", response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => reject(error))
            })
        },
        fetchRepositoryDetailView(ctx, queryParams) {
            ctx.commit('UPDATE_IS_LOADING', true)
            return new Promise((resolve, reject) => {
                axios
                    .get(`/bitbucket/repositories/${queryParams.id}`, {
                        params: queryParams
                    })
                    .then(response => {
                        resolve(response)
                        ctx.commit("FETCH_BITBUCKET_DETAIL", response)
                        ctx.commit('UPDATE_IS_LOADING', false)
                    })
                    .catch(error => reject(error))
            })
        },
        resetDetailView(ctx) {
            ctx.commit('RESET_DETAIL')
        },
        fetchBitbucketAllUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get('/bitbucket/members')
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    },
}
